.comingSoonContainer {
  background-color: var(--colorBackgroundComingSoon);
  min-height: var(--vh);
  display: flex;
  flex-direction: column;
}

.highlight {
  position: relative;
  background-size: cover;

  background-blend-mode: screen;
  background-color: var(--cologComingSoonBackgroundBlendColor);

  padding: 120px 16px 100px;
  font-size: 45px;
  font-weight: var(--fontBold);
  color: var(--colorComingSoonHighlight);
  text-align: center;

  /* NOTE: Triggers bug with iOS 13.6, background-blend-mode doesn't work */
  /* border-top: 1px solid var(--colorComingSoonHighlightBorder); */
}

.notify {
  padding: 56px 15px;
  flex-grow: 1;
}

.callText {
  color: var(--colorBlack);
  font-size: 30px;
  font-weight: var(--fontBold);
  margin-bottom: 11px;
}
