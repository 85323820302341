.comingSoonContainer {
  background-color: var(--colorBackgroundComingSoon);
  background-repeat: no-repeat;
  background-position: 0 40%;
  background-size: contain;
  min-height: var(--vh);
  display: flex;
  flex-direction: column;
}

.highlight {
  max-width: 790px;
  margin-left: 15%;
  padding: 100px 16px 65px;
  font-size: 80px;
  font-weight: var(--fontBold);
  color: var(--colorComingSoonHighlight);
}

.notify {
  max-width: 450px;
  margin: 56px 15px 56px 60%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.callText {
  color: var(--colorBlack);
  font-size: 30px;
  font-weight: var(--fontBold);
  margin-bottom: 5px;
  max-width: 265px;
}
