.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  border-bottom: 1px solid var(--colorComingSoonHighlightBorder);
}

.logo {
  width: 129px;
}
