.header {
  display: flex;
  justify-content: flex-end;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px 16px;
  min-width: 290px;
}

.symbol {
  font-size: 100px;
}

.message {
  padding: 0 0 34px;
  font-weight: var(--fontLight);
  font-size: 30px;
}

.icon {
  margin: 10px;
  cursor: pointer;
}

.dialog {
  border-radius: 8px;
  margin: 17px;
}
