.invitationFormContainer {
}

.emailField {
  flex-grow: 1;

  height: 50px;
  border: none;
  border-radius: 0;

  font-size: 14px;
  font-weight: var(--fontBold);
  padding: 0 8px;

  margin-right: 8px;
}

.submitButton {
  border-radius: 0;
  font-weight: var(--fontBold);
}

.primaryRow {
  display: flex;
}

.validationError {
  height: 18px;
  margin: 2px 0 0 8px;
  font-size: 12px;
  font-weight: var(--fontRegular);
  color: var(--colorRed);
}

.invitationError {
  margin: 2px 0 0 0;
  border: 1px solid var(--colorComingSoonInvitationStatusBorder);
  border-radius: 5px;
  padding: 9px 9px;
  font-size: 14px;
  font-weight: var(--fontRegular);
  text-align: center;
}
