.footerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
}

.copyrightNote {
  font-size: 14px;
  font-weight: var(--fontSemiBold);
  text-transform: uppercase;
  color: var(--colorFooterTextComingSoon);
}
