.container {
  display: flex;
  flex-direction: column;

  .content {
    min-height: calc(100vh - 118px - 38px);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px 20px;

    .title {
      font-size: 38px;
      font-weight: 600;
      line-height: 1.25;
      margin-bottom: 32px;
    }

    .header1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.25;
      margin-bottom: 16px;
    }

    .header2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.25;
      margin-bottom: 16px;
    }

    .header3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      margin-bottom: 16px;
    }

    .paragraph {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 16px;
    }

    .textContent {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }

    .list {
      margin-top: 0;
    }

    .underlined {
      text-decoration: underline;
    }

    .bold {
      font-weight: 600;
    }

    .italic {
      font-style: italic;
    }

    .link {
      color: #000;
      text-decoration: underline;
    }
  }
}
